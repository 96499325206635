import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Caption from './caption';
import MainContent from './mainContent';
import Interact from '../../../../../components/interact';
import TimeLine from './timeLine';
import { Comment } from 'ThreeTerminal_pc_dynamic';

class Artical extends React.PureComponent {
    static propTypes = {
        type: PropTypes.string,
        topic: PropTypes.string,
        commentInfo: PropTypes.object,
        content: PropTypes.object,
        qrCode: PropTypes.object,
        topicAd: PropTypes.object,
        ad: PropTypes.object,
        commentAd: PropTypes.object,
        chQrCode: PropTypes.object,
        isHitBlack: PropTypes.bool,
        commentGuide: PropTypes.object,
        hasCopyRight: PropTypes.bool,
        faceUrl: PropTypes.string,
        vestAccountDetail: PropTypes.object,
        subscribe: PropTypes.object,
        articleBottomAd: PropTypes.object,
        keywords: PropTypes.string,
        interact: PropTypes.object,
        timeLine: PropTypes.object,
        sourceReason: PropTypes.string,
        cutoffGray: PropTypes.object,
        base62Id: PropTypes.string,
    };

    isCutoffHandle = () => {
        const {
            cutoffGray,
            isHitBlack,
            content: { wemediaEAccountId },
        } = this.props;

        if (cutoffGray?.cutoffIds?.length > 0) {
            cutoffGray.cutoffIds = cutoffGray.cutoffIds.filter((item) => item.isClose === false) || [];
        }
        const cutoffGrayIds = cutoffGray?.cutoffIds?.map?.((item) => item.id) || [];
        const startTime = new Date(cutoffGray?.startTime).getTime();
        const endTime = new Date(cutoffGray?.endTime).getTime();
        const nowTime = new Date().getTime();
        let isTimeout = false;

        if (nowTime < startTime || nowTime > endTime) {
            isTimeout = true;
        }

        return cutoffGrayIds.includes(wemediaEAccountId) && cutoffGray?.percentage && !isHitBlack && !isTimeout;
    };

    hasCommonSubset = (arr1 = [], arr2 = []) => {
        for (let i = 0; i < arr1.length; i++) {
            for (let j = 0; j < arr2.length; j++) {
                if (arr1[i] === arr2[j]) {
                    return true;
                }
            }
        }

        return false;
    };

    render() {
        const {
            type,
            topic,
            commentInfo,
            content,
            qrCode,
            topicAd,
            ad,
            commentAd,
            chQrCode,
            isHitBlack,
            commentGuide,
            hasCopyRight,
            faceUrl,
            vestAccountDetail,
            subscribe,
            articleBottomAd,
            keywords,
            interact,
            timeLine,
            sourceReason,
            cutoffGray,
        } = this.props;
        const {
            noAd,
            noffhFlag,
            title,
            newsTime,
            fhhAccountDetail,
            author,
            source,
            sourceUrl,
            searchPath,
            summery,
            imagesInContent,
            base62Id,
            pcUrl,
            url,
            commentUrl,
            skey,
            voteId,
            isOriginal,
            contentData,
            editorName,
            editorCode,
            videosPluginData,
            slidesPluginData,
            surveyPluginData,
            goodsPlugin,
            createTime,
            filterMediaList,
            wemediaEAccountId,
        } = content;
        const pluginData = {
            videosPluginData,
            slidesPluginData,
            surveyPluginData,
            goodsPlugin,
        };
        const date = newsTime.split(' ')[0];
        const time = newsTime.split(' ')[1];
        const dateTime = `${date.split('-')[0]}年${date.split('-')[1]}月${date.split('-')[2]}日 ${time}`;
        const followUrl = `//gentie.ifeng.com/c/comment/${base62Id}`;
        const captionData = {
            ...commentInfo,
            type,
            noffhFlag,
            title,
            url,
            dateTime,
            fhhAccountDetail,
            author,
            searchPath,
            source,
            sourceUrl,
            summery,
            imagesInContent,
            commentUrl,
            followUrl,
            pcUrl,
            hasCopyRight,
            faceUrl,
            vestAccountDetail,
            subscribe,
            interact,
        };

        const shareInfo = {
            url: pcUrl,
            title,
            summery,
            picUrl: imagesInContent && imagesInContent.length > 0 ? imagesInContent[0].url : '',
        };

        const commentConfig = {
            noAd,
            // 文章单页的页面地址
            docUrl: commentUrl,
            // 文章的标题
            docTitle: title,
            // 文章评论的skey，这个是在后端生成好后给到前端的
            skey,
            // 兼容以后的专题评论
            speUrl: '',
            // 文章pc的页面地址
            pcUrl,
            // 获取评论内容接口
            cmtNumUrl: '//comment.ifeng.com/get.php',
            // 评论内容字数长度
            cmtLength: 1000,
            // 提交评论内容接口
            cmtPostUrl: '//comment.ifeng.com/post.php',
            // 最热评论数量
            hotLimit: 2,
            // 推荐评论接口
            cmtVoteUrl: '//comment.ifeng.com/vote.php',
            // 现实回复的楼层
            replyLimit: 3,
            // 举报提交接口
            jubUrl: '//comment.ifeng.com/report',
            // 举报次数，便于每次回调函数名不同
            jubNum: 0,
            // 是否是评论详情页面
            isComment: false,
            guidInfo: {
                backgroundImage: commentGuide.backgroundImage || '',
                qrcodeImage: commentGuide.qrcodeImage,
                isOpenGuide: (!!commentGuide.isOpenGuide && !isHitBlack) || false,
                countKey: commentGuide.countKey || '',
                isCheckPhone: commentGuide.isCheckPhone,
                setDay: commentGuide.setDay || '',
            },
        };
        const qrCodeData = {
            chQrCode,
            isHitBlack,
            searchPath,
        };
        const isShowEditName = editorCode && (hasCopyRight || sourceReason === '公开信息');

        const cutofffConfig = {
            createTime,
            base62Id,
            isCutoff: this.isCutoffHandle(),
            cutoffGray: cutoffGray?.percentage || 0,
            filterUA: cutoffGray?.filterUA,
        };
        const filterMediaIdList = filterMediaList.filter((item) => !!item.id).map((item) => item.id);
        const filterChannelIdList = filterMediaList.filter((item) => !!item.channelId).map((item) => item.channelId);
        const searchPathList = searchPath?.split?.(',') || [];
        const hasBlackMedia = filterMediaIdList.includes(wemediaEAccountId);
        const hasBlackSearchPath = this.hasCommonSubset(searchPathList, filterChannelIdList);

        return (
            <div className={styles.artical}>
                <Caption content={captionData} />
                {!isHitBlack && <Ad content={topicAd} />}
                {!isHitBlack && <TimeLine content={timeLine} />}
                <MainContent
                    topic={topic}
                    shareInfo={shareInfo}
                    content={contentData}
                    pluginData={pluginData}
                    ad={ad}
                    cutofffConfig={cutofffConfig}
                />
                {hasCopyRight &&
                    !interact.isCloseFhhCopyright &&
                    !hasBlackMedia &&
                    !hasBlackSearchPath &&
                    isOriginal !== '1' &&
                    isOriginal !== '2' && (
                        <div className={styles.copyRight}>
                            <p className={styles.copyRightCh}>
                                “特别声明：以上作品内容(包括在内的视频、图片或音频)为凤凰网旗下自媒体平台“大风号”用户上传并发布，本平台仅提供信息存储空间服务。
                            </p>
                            <p className={styles.copyRightZh}>
                                Notice: The content above (including the videos, pictures and audios if any) is uploaded
                                and posted by the user of Dafeng Hao, which is a social media platform and merely
                                provides information storage space services.”
                            </p>
                        </div>
                    )}
                <NoSSR>
                    <div className={styles.artical_line} />
                    <div className="clear" />
                    <div className={styles.vote_box}>
                        {isShowEditName ? (
                            <p className={styles.author}>{`[责任编辑：${editorName} ${editorCode}]`}</p>
                        ) : (
                            ''
                        )}
                        {isOriginal === '1' || isOriginal === '2' ? (
                            <p className={styles.copyrightInfo}>(本文章版权归凤凰网所有，未经授权，不得转载)</p>
                        ) : (
                            ''
                        )}
                        <div className="clear" />
                        {!isHitBlack && (
                            <div className={styles.articleBottomAd}>
                                <Ad content={articleBottomAd} />
                            </div>
                        )}
                        <div className="clear" />
                        <Interact keywords={keywords} voteId={voteId} shareInfo={shareInfo} content={interact} />
                    </div>
                    <div id="articleBottomAdAuto" />
                    {!isHitBlack && <Ad content={commentAd} styleName={styles.comment_b} />}
                    {/* <LazyLoad> */}
                    <Chip id="260124" type="struct" title="评论浮层引导维护" groupName="评论浮层引导维护" />
                    <Comment articleInfo={commentConfig} />
                    {/* </LazyLoad> */}
                </NoSSR>
            </div>
        );
    }
}

export default errorBoundary(Artical);
